import { graphql } from 'gatsby';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

import FAQList from '../components/FAQ/FAQList/FAQList';
import Anchors from '../components/global/anchors/anchors';
import Banner, { pageType } from '../components/global/banner/banner';
import Layout from '../components/global/layout/layout';
import { PageBase } from '../context/global-context';
import { HelpData } from '../types/help';
import { PageContext } from '../types/page';
import { useI18n } from '../utils/i18n';

export const query = graphql`
  query Help($locale: CreeGraphql_I18NLocaleCode) {
    creegraphql {
      help(locale: $locale) {
        data {
          attributes {
            locale
            slug
            title
            sections {
              title
              items {
                question
                answer
              }
            }
            localizations {
              data {
                attributes {
                  locale
                  slug
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Help: React.FC<{
  pageContext: PageContext;
  data: {
    creegraphql: {
      help: {
        data: HelpData;
      };
    };
  };
}> = ({ pageContext, data }) => {
  const { translations } = pageContext;

  const i18n = useI18n(translations);

  const page = data.creegraphql.help;

  const attributes = page.data.attributes;
  const anchors = attributes.sections.map((section) => {
    return section.title;
  });

  return (
    <PageBase translations={translations} instance={page.data}>
      <Layout>
        <Banner
          title={attributes.title}
          bannerType="help-page"
          pageType={pageType.page}
        />
        <main className="help">
          <Row>
            <Col lg={4} xl={3} className="sticky">
              <Anchors titles={anchors} defaultText={attributes.title} />
            </Col>
            <Col lg={1} />
            <Col lg={7} xl={8} className="gx-4">
              <FAQList sections={attributes.sections} />
            </Col>
          </Row>
        </main>
      </Layout>
    </PageBase>
  );
};

export default Help;
