import React, { Fragment, useContext } from 'react';
import { Row } from 'react-bootstrap';

import GlobalContext from '../../../context/global-context';

import './footer.scss';

import FooterContent from './footerContent';

const renderFooterMenu = (): JSX.Element => {
  const { i18n } = useContext(GlobalContext);

  return (
    <Fragment>
      <FooterContent />
      <Row className="legal">
        <span>{i18n?.t('copyright')}</span>
      </Row>
    </Fragment>
  );
};

const Footer: React.FC = () => {
  return <footer className="footer-menu">{renderFooterMenu()}</footer>;
};

export default Footer;
