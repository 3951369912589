import React from 'react';

import { FAQSection } from '../../../types/help';

import './FAQItem.scss';

const FAQItem: React.FC<{
  section: FAQSection;
  id: string;
}> = ({ section, id }): JSX.Element => {
  const { title, items } = section;

  const renderParagraph = (
    question: string,
    answer: string,
    index: number
  ): JSX.Element => {
    return (
      <div key={`${question}${index}`}>
        <h4>{question}</h4>
        <p>{answer}</p>
      </div>
    );
  };

  return (
    <div id={id} className="question">
      <h2>{title}</h2>
      {items.map((item, index) => {
        return renderParagraph(item.question, item.answer, index);
      })}
    </div>
  );
};

export default FAQItem;
