import { navigate } from 'gatsby';
import React from 'react';

import GlobalContext from '../../../context/global-context';
import { useLocaleFromRouter } from '../../../hooks/useLocaleFromRouter';
import { ReactComponent as BackSVG } from '../../../images/back.svg';
import { LocaleType } from '../../../utils/i18n';

import './banner.scss';

export enum pageType {
  page = 'page',
  item = 'item',
}
type BannerType = {
  title: string;
  pageType: pageType;
  bannerType?: string;
  crumbs?: {
    previousPageLink: string;
    previousPageTitle: string;
    crumbTitle: string;
  };
};

const Banner: React.FC<BannerType> = ({
  title,
  pageType,
  bannerType,
  crumbs,
}) => {
  const { i18n } = React.useContext(GlobalContext);
  const currentLocale: LocaleType = useLocaleFromRouter();

  const renderPageBanner = () => {
    return (
      <div className="plain-banner">
        <div className={`text-container ${bannerType ? bannerType : ''}`}>
          <span
            onClick={() => {
              navigate(`/${currentLocale}/`);
            }}
            className="link"
          >
            {`${i18n?.t('breadcrumb.home')} > `}
          </span>
          <span>{title}</span>
          <h2>{title}</h2>
        </div>
      </div>
    );
  };

  const renderItemBanner = () => {
    return (
      <div className="plain-banner">
        <div className={`text-container ${bannerType ? bannerType : ''}`}>
          <span
            className="link"
            onClick={() => {
              navigate(`/${currentLocale}/`);
            }}
          >
            {`${i18n?.t('breadcrumb.home')} > `}
          </span>
          {crumbs && (
            <span
              className="link"
              onClick={() => {
                navigate(
                  crumbs ? `/${currentLocale}/${crumbs.previousPageLink}` : ''
                );
              }}
            >
              {`${crumbs ? crumbs.previousPageTitle : ''} > `}
            </span>
          )}
          <span>{crumbs ? crumbs.crumbTitle : ''}</span>

          <div>
            <span
              onClick={() =>
                navigate(
                  crumbs ? `/${currentLocale}/${crumbs.previousPageLink}` : ''
                )
              }
            >
              <BackSVG
                width={35}
                height={45}
                fill="#FFFFF8"
                viewBox="7 3 15 30"
              />
            </span>
            <h3>{title}</h3>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {pageType === 'page' && renderPageBanner()}
      {pageType === 'item' && renderItemBanner()}
    </>
  );
};

export default Banner;
