import * as React from 'react';

import Footer from '../footer/footer';
import Header from '../header/header';
import './layout.scss';

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
};

export default Layout;
