import { navigate } from 'gatsby-link';
import React, { useContext } from 'react';
import slugify from 'slugify';

import GlobalContext from '../../../context/global-context';
import { useLocaleFromRouter } from '../../../hooks/useLocaleFromRouter';
import { FAQSection } from '../../../types/help';
import { LocaleType } from '../../../utils/i18n';
import { generateKey } from '../../../utils/keys';
import CustomButton, {
  buttonOptions,
} from '../../global/customButton/customButton';
import FAQItem from '../FAQItem/FAQItem';

const FAQList: React.FC<{ sections: FAQSection[] }> = ({
  sections,
}): JSX.Element => {
  const currentLocale: LocaleType = useLocaleFromRouter();
  const { i18n } = useContext(GlobalContext);
  const renderFAQList = () => {
    return sections.map((section, index) => {
      const id = slugify(section.title.toLocaleLowerCase().replace("'", ''));
      const key = generateKey(`help_section_${index}`);

      return (
        <div key={key}>
          <FAQItem section={section} id={id} />
        </div>
      );
    });
  };

  const pathParts = [currentLocale, i18n?.t('contact_us.slug')];
  return (
    <>
      {renderFAQList()}
      <div>
        <h4>{i18n?.t('faq.contact.title')}</h4>
        <CustomButton
          style={buttonOptions.outline}
          onClick={() => navigate(`/${pathParts.join('/')}`)}
          className="register-business-cta"
        >
          {i18n?.t('faq.contact.cta')}
        </CustomButton>
      </div>
    </>
  );
};

export default FAQList;
