import { graphql, Link, navigate, useStaticQuery } from 'gatsby';
import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';

import GlobalContext from '../../../context/global-context';
import { useLocaleFromRouter } from '../../../hooks/useLocaleFromRouter';
import { ReactComponent as CreeLogoSVG } from '../../../images/cree_logo_svg.svg';
import {
  FooterAttributes,
  FooterData,
  FooterQueryData,
} from '../../../types/footer';
import { LinkData } from '../../../types/general';
import { LocaleType } from '../../../utils/i18n';
import CustomButton, { buttonOptions } from '../customButton/customButton';

const FooterContent = (): JSX.Element => {
  const { i18n, isLogged } = useContext(GlobalContext);

  const footerContentQueryData = useStaticQuery<FooterQueryData>(graphql`
    query FooterContentQuery {
      creegraphql {
        footer {
          data {
            attributes {
              locale
              footerLinkPrimary {
                ariaLabel
                target
                text
                url
              }
              footerLinkSecondary {
                ariaLabel
                target
                text
                url
              }
              footerCTA {
                ariaLabel
                target
                text
                url
              }
              contactCard {
                addressLine1
                addressLine2
                addressLine3
                postalCode
                subTitle
                title
              }
              localizations {
                data {
                  attributes {
                    locale
                    contactCard {
                      addressLine1
                      addressLine2
                      addressLine3
                      id
                      postalCode
                      subTitle
                      title
                    }
                    footerCTA {
                      ariaLabel
                      target
                      text
                      url
                    }
                    footerLinkSecondary {
                      ariaLabel
                      target
                      text
                      url
                    }
                    footerLinkPrimary {
                      ariaLabel
                      target
                      text
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const currentLocale: LocaleType = useLocaleFromRouter();

  const getLocalizedFooter = (
    footer: FooterData | null,
    locale: LocaleType
  ): FooterAttributes | null => {
    if (footer != null) {
      // If it matches the English entry, return footer data.
      if (footer.data.attributes.locale === locale) {
        return footer.data.attributes;
      } else {
        // If one of the footer localizations matches the requested locale.
        const localization = footer.data.attributes.localizations.data.find(
          (l) => l.attributes.locale === locale
        );

        if (typeof localization !== 'undefined') return localization.attributes;
      }
    }
    // Return null if there is no footer data.
    return null;
  };

  const footerElements = getLocalizedFooter(
    footerContentQueryData.creegraphql.footer,
    currentLocale
  );

  function handleRegisterMyBusinessClick() {
    !isLogged
      ? navigate(`/${currentLocale}/${i18n?.t('register.slug')}`)
      : footerElements?.footerCTA.url && navigate(footerElements.footerCTA.url);
  }

  const renderPrimaryLink = () => {
    return (
      <Col xs={7} md={4} className="primary-links">
        <div className="primary-links-container">
          {footerElements != null &&
            footerElements.footerLinkPrimary.map(
              (primaryLinkItem: LinkData) => {
                const { url, text, target, ariaLabel } = primaryLinkItem;

                return (
                  <Link
                    key={text}
                    to={url}
                    target={target}
                    aria-label={ariaLabel}
                    className="link nav-link"
                  >
                    {text}
                  </Link>
                );
              }
            )}
        </div>
        {footerElements?.footerCTA && renderCTA()}
      </Col>
    );
  };

  const renderSecondaryLink = () => {
    return (
      <Col xs={5} md={3} className="secondary-links">
        {footerElements != null &&
          footerElements.footerLinkSecondary.map(
            (secondaryLinkItem: LinkData) => {
              const { url, text, target, ariaLabel } = secondaryLinkItem;

              return (
                <Link
                  key={text}
                  to={url}
                  target={target}
                  aria-label={ariaLabel}
                  className="link nav-link"
                >
                  {text}
                </Link>
              );
            }
          )}
      </Col>
    );
  };

  const renderCTA = () => {
    return (
      <CustomButton
        target={footerElements?.footerCTA.target}
        aria-label={footerElements?.footerCTA.ariaLabel}
        style={buttonOptions.filled_light}
        classSuffix="--small"
        className="register-business-cta"
        onClick={handleRegisterMyBusinessClick}
      >
        {footerElements?.footerCTA.text}
      </CustomButton>
    );
  };

  const renderContactCard = () => {
    return (
      <Col
        md={{ order: 'first' }}
        xs={{ order: 'last' }}
        className="address-info"
      >
        <div className="footer-logo ">
          <CreeLogoSVG className="logo" />
          <div className="title">
            <div>{footerElements?.contactCard?.title}</div>
            <div>{footerElements?.contactCard?.subTitle}</div>
          </div>
        </div>
        <div>
          <div className="address">
            <div>{footerElements?.contactCard?.addressLine1}</div>
            <div>{`${footerElements?.contactCard?.addressLine2} ${footerElements?.contactCard?.postalCode}`}</div>
          </div>
        </div>
      </Col>
    );
  };

  return (
    <Row>
      {footerElements?.contactCard && renderContactCard()}
      {footerElements?.footerLinkPrimary && renderPrimaryLink()}
      {footerElements?.footerLinkSecondary && renderSecondaryLink()}
    </Row>
  );
};

export default FooterContent;
